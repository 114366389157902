import React from 'react'

const Dashboard = () => {
return (
    <div>
        <h1>Welcome {}</h1>
    </div>
)
}

export default Dashboard